<template>
  <section>
    <div class="content-header">
      <h2>Asian identity</h2>
    </div>
    <div class="content-wrapper">
      <p>This section on Asian identity is a recognition of the spectrum of ethnicities, nations, cultures, languages, religions, societies and histories of people of Asian descent. As we learn about different Asian identities, it’s important to note that the word “Asian” itself is non-specific and far too general. “Asian” refers to a person who comes from or is associated with a country or region in the continent of Asia. This term, without social, historical or cultural context, is largely geographical in nature. </p>
      <p>Here, we explore sub-categories that in themselves consist of multiple national, ethnic, religious, linguistic and cultural groups. Given Asia’s history, these categorizations are also shaped by socio-cultural and political histories. The categories listed below have been taken from the “Ethnic origin population” category of Canada’s 2016 Census. It’s worth noting that Canada’s Census classification of West Asian is not aligned with typical cartographic representations of West Asian countries. </p>
      <p>In presenting these sub-groups, the hope is that we consider what is beyond and beneath these groupings and inspire further learning. At large, Asian Canadians represent 17.7% of Canada’s population. This percentage can be further disaggregated as follows: </p>
      <ul>
        <li>East Asian (34.2%) </li>
        <li>South Asian (31.3%) </li>
        <li>Southeast Asian (18.9%) </li>
        <li>West Asian (13.6%) </li>
      </ul>
      <p>In recent centuries, the term Asian has often been framed from a colonial or “Western” lens. It was not uncommon for the British to use the term ‘Asian’ to refer to India, Pakistan and Bangladesh, while the Americans more commonly used the term to refer to China, Korea, Thailand, Japan, and/or Vietnam. </p>
      <p>The classification of “Asian” is also shaped by a “Western gaze” (Said, 1978), imbuing homogeneity where it does not exist. These portrayals today act as a barrier to fully engaging with the many unique identities that originate from the Asian continent. While a broad classification might allow communities to organize around a shared logic—for example, as it relates to anti-racism grassroots and social movements—no homogeneity should be assumed of an “Asian” identity. This section captures a subset of historical and contemporary moments as it pertains to some Asian identities. </p>
      <div class="accordion">
        <accordion
          headerText="Anti-Asian racism today "
          accordionID="collapse01"
        >
          <p>Anti-Asian racism has been growing around the world, and especially here in North America. In Canada, reports of anti-Asian harassment, discrimination and violent hate crimes, are increasing at alarming rates. A Statistics Canada survey from last September found that nearly one-third of Chinese Canadians experience anti-Asian racism in their day-to-day lives, with women feeling it disproportionately. The rise of anti-Asian racism in Canada is also evident throughout the United States. The killing of eight people in Atlanta—six of them Asian women—serves to highlight this issue. More specifically, anti-Asian hate crimes increased by nearly 150% in 2020. New data has revealed over the past year, the number of anti- Asian hate violence—which included shunning, slurs and physical attacks—is greater than previously reported. Those attacks have left Asian communities across the country deeply concerned. When COVID-19 was described as the “Wuhan Flu,” or “Kung Flu,” or the “China Virus,” by several authorities and popular figures, it further fueled anti-Asian racism in Canada and around the world. </p>
          <p>As we recognize anti-Asian racism today and how COVID has fueled Asian hate, it’s also essential to recognize similar patterns in history. The creation and growth of Asian communities in Canada in the 19th and early 20th centuries also had significant consequences for medical inspection and health care. During a 1907 outbreak of bubonic plague in San Francisco, California, the BC legislature ordered that “all sick Chinese, Japanese, Sikhs or other Orientals” inform local health authorities of their condition, and Vancouver’s medical inspectors uniquely targeted Asian neighbourhoods in their search for plague victims. In Montreal during the Spanish influenza epidemic of 1918, civic officials frequently excluded Chinese patients from the city’s “white” hospitals, leading to the Chinese Benevolent Association’s establishment of a Chinese hospital. In both epidemics, Euro-Canadian associations between Asians and infectious disease were predicated on racist stereotypes of unhygienic “Orientals.” Journalists, politicians and others often portrayed these people as dirty, unsanitary and a threat to public health.</p>
          <p>Brief aside: More recently, we’ve heard from a few celebrities in the media on bathing habits. These conversations have seemed harmless and amusing, but historically, the notion of cleanliness has been used against immigrants, racialized and persecuted groups of people to justify oppression and ostracization. Stereotypes that portray groups of people as dirty aim to uphold the idea of the purity and cleanliness of the dominant group are in fact harmful and rooted in unconscious biases. </p>
          <p>Even in London, Ontario, a marquee board at a downtown bar was in the news for displaying anti-Asian messaging. The board read, "Mr. Ford, history will show lockdowns caused more damage 2 the public then [sic] the China virus!" This incident indicates that anti-Asian racism exists and is closer to home than we often recognize. </p>
        </accordion>
        <accordion
          headerText="Model minority myth and codeswitching "
          accordionID="collapse02"
        >
          <p>Racism, including microaggressions toward Asians, are often less visible, yet highly prevalent. Being told that they do not sound “Asian” or being asked if their family “owns a Chinese Restaurant” are common experiences for many Asian-Canadians. Such microaggressions are hard to report and provide evidence for. They do not show up in the data. Thus, it’s often difficult to quantify the number of microaggressions Asian individuals experience on a daily basis. </p>
          <p>Further, there is also a model minority myth applied to East, Southeast and South Asian communities in North America. The model minority myth refers to a perception of a minority group as particularly being more successful especially as it contrasts to other minority groups. Pitting races against each other has been a common strategy used by colonizers: divide and conquer. The model minority myth is a very delicate issue because of these racial nuances. This then makes it harder for Asian Identities to talk about their experience with racism because they are seen to be successful and should not have any complaints about structural or systemic racism. </p>
          <p>Similarly, codeswitching is a phenomena that involves changing one’s mannerisms and behaviour around other peers and colleagues in order to fit in with the majority present. The concept of code-switching implies that one’s culture including culturally specific phrases, food preferences, music taste and accents would serve to their detriment, not their benefit. A desire to appear more homogenous with the dominant culture comes from an overall societal stance that everyone must fit into the desired mold. </p>
        </accordion>
        <accordion
          headerText="Intersectionality "
          accordionID="collapse03"
        >
          <p>In the wake of the Atlanta shootings, it’s important to draw more awareness to the racism that Asian-Canadians have faced historically and increasingly since the onset of the pandemic. We must educate on the intersection of racism and sexism and the lack of visibility and attention given to their pain. The victims of the Atlanta shooting were not only victims to anti-Asian violence but were also targeted for their gender and socioeconomic status. </p>
          <p>A national report by Stop AAPI Hate documenting incidents of racism and discrimination against Asian Americans last year found that women reported hate incidents 2.3 times more than men. Asian-American women have been stereotyped in ways that are racialized, gendered and sexualized with slurs including "the dragon lady" to "the lotus blossom". Hence, we must challenge ourselves to consider the roles of race, gender, heterosexuality, and class as intersecting identities that reinforce each other and further shape the lived experiences of many. </p>
        </accordion>
        <accordion
          headerText="Moments in history "
          accordionID="collapse04"
        >
          <p>Understanding the history of people’s journey or lived experiences is critical for our better understanding of an individual's contemporary experiences of identity. In this section, we walk through moments of history through four regions of East, South, Southeastern, and West Asia. </p>
          <h5>East Asia</h5>
          <p>East Asian Canadians consist of several ethnicities and/or nationalities, such as Chinese Canadian, Hong Kong Canadian, Japanese Canadian, Korean Canadian, Mongolian Canadian, Taiwanese Canadian or Tibetan Canadian. </p>
          <p>Historically, Chinese pioneers followed gold rushes to the Fraser River in British Columbia in 1858, and then many Chinese labourers were contracted to build the transcontinental railway. The first Korean migrants to Canada were seminary students sent here by Canadian missionaries. Korean migration to Canada boomed in the late 1960s and continues today. The first documented Japanese immigrant arrived in British Columbia in 1877, six years after Confederation. Japanese people arrived in Canada in two major waves. The first generation of immigrants, called Issei, arrived between 1877 and 1928, and the second after 1967. The vast majority of Issei settled in communities along the Pacific Coast, in the Fraser Valley and in the suburbs of Vancouver and Victoria. A few took up residence in the surrounding areas of Lethbridge and Edmonton in Alberta. </p>
          <p><strong>Chinese immigrants </strong></p>
          <p>By the time the railway was completed in 1885, immigration policy changed significantly premised on anti-Chinese racism once the economic rationale was no longer present. The Canadian government chose to introduce the federal Chinese Head Tax that year. This tax was originally set at $50, this entry tax for Chinese immigrants was eventually increased to $500 and remained in effect until the introduction of the Chinese Immigration Act of 1923, which barred most new Chinese settlement in Canada. While 39,000 people of Chinese origin lived in Canada in 1921, Chinese exclusion halted population growth, ultimately reducing it to approximately 32,000 by the time the Act was repealed in 1947. </p>
          <p><strong>Japanese immigrants</strong></p>
          <p>The first generations of Japanese Canadians were denied the full rights of citizens, such as the right to vote in provincial and federal elections and to work in certain industries. British Columbia’s resident population believed that Japanese economic competitiveness and Japan’s rising global status made them a greater threat to Canadian society than the Chinese. After aggressive anti-Japanese lobbying by the province, in 1908 Canada negotiated the Hayashi-Lemieux “Gentleman’s Agreement,” in which Japan’s government agreed to limit the immigration of its male citizens to Canada to 400 per annum. This number was later decreased to 150, and the policy remained in effect until the Second World War which brought the end of Japanese immigration entirely. </p>
          <p>During this War, the federal government interned and dispossessed over 20,000 Japanese Canadians. They were sent into internment camps and exiled from the west coast. All those of Japanese descent, including naturalized Canadians and those born in Canada were banned from living in the city of Toronto between 1942 and 1945. </p>
          <h5>South Asia</h5>
          <p>South Asian Canadians are Canadians who can trace their ancestry to South Asia, which includes the nations of India, Pakistan, Bangladesh, Sri Lanka, Bhutan, Nepal and the Maldives. </p>
          <p>The first few hundred South Asian immigrants to Canada came from Hong Kong or one of the other British Far Eastern strongholds. The majority of them were Sikhs. About 100 years ago, between 1905 and 1908, some 5,000 South Asians arrived in British Columbia, mainly for employment.</p>
          <p>The predominantly male population, mostly Punjabi Sikhs, worked mainly in railroad construction and in the logging and lumber industries. The South Asian community in Canada remained relatively small and homogenous throughout the early 1900s because of restrictive immigration laws prohibiting Asian and Indian immigration. In 1908, Canada’s government introduced an order in council prohibiting immigrants who had not arrived by continuous journey from their land of origin. This was seen to be in response to anti-Indian racism and populist concerns that South Asians were taking jobs from white workers. Since this method of transportation was impossible from India, the act effectively ended South Asian immigration until 1947, when less than 2,500 South Asians remained in the province. </p>
          <p>In 1971, Prime Minister Pierre E. Trudeau’s multiculturalism policies were enacted and the opportunities for immigration to Canada began to increase for South Asian communities. These policies prioritized employment skills, education and language ability to select immigrants rather than race or country of origin. The South Asian community in Canada has evolved from a relatively small and homogenous population to one that is unique in its diversity, boasting a multitude of different birthplace origins, ethnicities, religions and languages. </p>
          <p>Colonialism in South Asian countries contributed to many today grappling with internalized racism linked to social and cultural conditioning of inferiority. In the book <em>Black Skin, White Masks</em>, author Franz Fanon describes that, from early childhood, the colonized population learns to associate their race with “wrongness” because of enforced policies and practices during the years of colonial powers controlling lands throughout South Asia. The cultural damage of colonization is that everyone—both the colonizer and the colonized come to believe that “whiteness is virtue and beauty”. Some individuals whose countries experienced colonization have internalized the perceived euro-centric standards of beauty. This colonial legacy and the perceived proximity to Whiteness has given rise to terms such as “white-washed” which can be used as either a compliment or an insult to how one embodies their Asian identity. While this does not apply to everyone, it is a dissonance of identity faced by many Asian Canadians today. </p>
          <h5>Southeast Asia </h5>
          <p>Southeast Asian Canadians are people who originate from the nations and cultures that are south of China and east of India. More specifically, Southeast Asia comprises 11 countries: Brunei Darussalam, Cambodia, Indonesia, Laos, Malaysia, Myanmar (Burma), the Philippines, Singapore, Thailand, East Timor and Vietnam. </p>
          <p>Filipinas and Filipinos started arriving in Canada during the late 1950s despite the restrictive policies of the Immigration Act of 1952. Later Acts—the Immigration Act of 1976 and the Immigration and Refugee Protection Act of 2002—were more welcoming, thereby encouraging more Filipinas’ and Filipinos’ migration to Canada. Some Filipina and Filipino doctors on the U.S. Exchange Visitors Program came to Canada to renew their visas from outside the United States as required. Some decided to stay. More came to Canada the following year as the passage of the U.S. Immigration and Nationality Act of 1965 forced them to leave the U.S. </p>
          <p>The settlement of Vietnamese nationals in Canada is relatively recent. It resulted from two waves of immigration in the aftermath of the Vietnam War. The first wave consisted mostly of middle-class people who were welcomed to Canada for their professional skills after the fall of Saigon in 1975. Since Vietnam had been part of the French colony of Indochina until 1954, Vietnamese immigrants generally spoke French. The second wave of immigration consisted of refugees from the former South Vietnam, seeking to escape the harsh living conditions and deteriorating human-rights following the reunification of the two Vietnams into a single country. </p>
          <p>Thai immigration to Canada dates to the 1950s. The first immigrants from Thailand consisted primarily of students who stayed in Canada after their studies. As a result of the 1997 financial crisis in Thailand, many Thais looked to countries overseas, including Canada, for work and educational opportunities. </p>
          <h5>West Asia </h5>
          <p>Canadians of West Asian origin make up one of the largest non-European ethnic groups in Canada; with the largest four groups of the West Asian population identifying as Iranian, Armenian, Afghans and Turks. The majority of West Asian Canadians immigrated over the last 30 years, and predominantly live in Toronto, Montreal or Vancouver. </p>
        </accordion>
        <accordion
          headerText="Supporting the Afghan community"
          accordionID="collapse05"
        >
          <p>At the time this was written, the humanitarian and geopolitical crisis in Afghanistan has been on the minds of many. Afghanistan is a landlocked country that is geographically situated in the South and Central regions of Asia, although past Canadian census data has classified Afghan origin within West Asia. </p>
          <p>We want to share the following resources for anyone wishing to support the Afghan community during this time of crisis. </p>
          <ul>
            <li><strong>Donate:</strong> You can donate food and hygiene kits to those in need through the <a
                href="https://idrf.ca/project/afghanistan-appeal/"
                target="_blank"
              >Afghan Emergency Relief</a> </li>
            <li><strong>Volunteer:</strong> You can volunteer with Afghan refugees who will be resettled in Canada by emailing <a
                href="mailto:afghancanadianresponse@gmail.com "
                target="_blank"
              >afghancanadianresponse@gmail.com</a> </li>
            <li><strong>Amplify:</strong> You can participate in the conversation to bring more attention to the ongoing crisis by connecting with the <a
                href="http://www.ayedi.ca/"
                target="_blank"
              >Canadian Campaign for Afghan Peace</a> </li>
            <li><strong>Learn:</strong> To learn more about the ongoing crisis or to receive additional guidance, you can connect with <a
                href="mailto:canafg@outlook.com"
                target="_blank"
              >canafg@outlook.com</a> </li>
          </ul>
        </accordion>
      </div>

    </div>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
